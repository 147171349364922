const dev = {
  environment: 'DEVELOPMENT',
  auth0: {
    domain: 'elderdev.eu.auth0.com',
    clientId: 'SVL3IWM4r6fgmFrdpWH8AOeQvDSmUCbq',
  },
  rest: {
    baseEndpoint: 'https://api-dev.elder.org',
  },
  stripe: {
    key: 'pk_test_y62y668QivkyE02XW3dWA5Xj',
  },
  proPortal: {
    url: 'carers-dev.elder.org',
  },
  sentry: {
    environment: 'development',
    projectUrl: 'https://f76221d8c4ba423fab7239cacc134088@sentry.io/417971',
    whitelistUrls: ['elder\\.org/', 'auth0\\.com'],
  },
  featureFlags: [
    'THIRD_PARTY_CARE',
    'STRIPE_BANK_TRANSFER_ENABLED',
    'AI_PROMPT',
  ],
  amplitudeApiKey: '05f5ffe7fa4255289427709dc23c5e21',
  salesforceBase: 'https://elder--dev2.sandbox.lightning.force.com',
  talkJSAppId: 'taSrNLuh',
  displaySeHierarchyFeatures: true,
  leasingService: {
    baseEndpoint: 'https://api-dev.elder.org/test/auth0-account-leasing',
    apiKey: '5D8602A2-FDD4-4F5F-8309-9BB553C71366',
  },
  vercel: {
    teamId: 'team_ThLsnYxxllclpfbTkRiyW3Ax',
    myElderProjectId: 'prj_BDHqMEuOwn6KGeaBAWxa5B3FVyyI',
  },
}

const demo = {
  environment: 'DEMO',
  auth0: {
    domain: 'elderstaging.eu.auth0.com',
    clientId: '1wnlmJWZ2zGdpALdZkKEqW1S19fTKDOp',
  },
  rest: {
    baseEndpoint: 'https://api-dev-demo.elder.org',
  },
  stripe: {
    key: 'pk_test_y62y668QivkyE02XW3dWA5Xj',
  },
  proPortal: {
    url: 'carers-dev-demo.elder.org',
  },
  sentry: {
    environment: 'demo',
    projectUrl: 'https://f76221d8c4ba423fab7239cacc134088@sentry.io/417971',
    whitelistUrls: ['elder\\.org/', 'auth0\\.com'],
  },
  featureFlags: [],
  amplitudeApiKey: '05f5ffe7fa4255289427709dc23c5e21',
  salesforceBase: 'https://elder--staging.sandbox.lightning.force.com',
  talkJSAppId: 'taSrNLuh',
  displaySeHierarchyFeatures: true,
}

const live = {
  environment: 'LIVE',
  auth0: {
    domain: 'elder.eu.auth0.com',
    clientId: 'Mne2QJMNgD6B5E7C0W8APiBOAlEr7uww',
  },
  rest: {
    baseEndpoint: 'https://api.elder.org',
  },
  stripe: {
    key: 'pk_live_n8sYVku183IAJp8ZQWchYJ7R',
  },
  proPortal: {
    url: 'carers.elder.org',
  },
  sentry: {
    environment: 'production',
    projectUrl: 'https://f76221d8c4ba423fab7239cacc134088@sentry.io/417971',
    whitelistUrls: ['elder\\.org/', 'auth0\\.com'],
  },
  featureFlags: ['STRIPE_BANK_TRANSFER_ENABLED'],
  amplitudeApiKey: '05f5ffe7fa4255289427709dc23c5e21',
  salesforceBase: 'https://elder.lightning.force.com',
  talkJSAppId: 'OaXofgLF',
  displaySeHierarchyFeatures: false,
}

const liveBeta = {
  ...live,
  rest: {
    baseEndpoint: 'https://api-beta.elder.org',
  },
}

const stagingCanary = {
  featureFlags: dev.featureFlags,
  environment: 'STAGING',
  auth0: {
    domain: 'elderstaging.eu.auth0.com',
    clientId: '1wnlmJWZ2zGdpALdZkKEqW1S19fTKDOp',
  },
  rest: {
    baseEndpoint: 'https://api-staging.elder.org',
  },
  stripe: {
    key: 'pk_test_y62y668QivkyE02XW3dWA5Xj',
  },
  proPortal: {
    url: 'carers-staging.elder.org',
  },
  sentry: {
    environment: 'staging-canary',
    projectUrl: 'https://f76221d8c4ba423fab7239cacc134088@sentry.io/417971',
    whitelistUrls: ['elder\\.org/', 'auth0\\.com'],
  },
  amplitudeApiKey: '05f5ffe7fa4255289427709dc23c5e21',
  salesforceBase: 'https://elder--staging.sandbox.lightning.force.com',
  talkJSAppId: 'taSrNLuh',
  displaySeHierarchyFeatures: true,
}

const staging = {
  environment: 'STAGING',
  auth0: {
    domain: 'elderstaging.eu.auth0.com',
    clientId: 'ZXLdGggHccGXbifL7MFj5RrWpFLwOlmF',
  },
  rest: {
    baseEndpoint: 'https://api-staging.elder.org',
  },
  stripe: {
    key: 'pk_test_y62y668QivkyE02XW3dWA5Xj',
  },
  proPortal: {
    url: 'carers-staging.elder.org',
  },
  sentry: {
    environment: 'staging',
    projectUrl: 'https://f76221d8c4ba423fab7239cacc134088@sentry.io/417971',
    whitelistUrls: ['elder\\.org/', 'auth0\\.com'],
  },
  featureFlags: ['STRIPE_BANK_TRANSFER_ENABLED'],
  amplitudeApiKey: '05f5ffe7fa4255289427709dc23c5e21',
  salesforceBase: 'https://elder--staging.sandbox.lightning.force.com',
  talkJSAppId: 'taSrNLuh',
  displaySeHierarchyFeatures: true,
}

const configs = {
  dev,
  demo,
  live,
  'live-beta': liveBeta,
  'staging-canary': stagingCanary,
  staging,
}

export const getConfig = () => {
  const configName = window.CONFIG_NAME || 'dev'
  const config = configs[configName] || configs.dev
  return config
}
