// @flow
import React from 'react'

import { EditOutlined } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

import { getConfig } from 'app/config'
import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'
import { AccountSection } from 'components/styledComponentsTranslatedClasses/AccountSection'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { AccountExecutiveHierarchy } from 'features/accountExecutive/AccountExecutiveHierarchy'
import { AutomatedCommunicationsEnabled } from 'features/accountInfo/AutomatedCommunicationsEnabled'
import { CanAccessPortalWithReadOnlyPermissionsEnabled } from 'features/accountInfo/CanAccessPortalWithReadOnlyPermissionsEnabled'
import { CareAgreementSection } from 'features/accountInfo/CareAgreementSection'
import { getLabeledCaVersion } from 'features/accountInfo/CareAppraisalVersion'
import { CareDatesSection } from 'features/accountInfo/CareDatesSection'
import { ExecutivesHierarchy } from 'features/accountInfo/ExecutivesHierarchy'
import { SalesforceOwner } from 'features/accountInfo/SalesforceOwner'
import { StatusButtons } from 'features/accountInfo/StatusButtons'
import type { ElderUser } from 'features/elderUsers/domain'
import type { Account } from 'routes/account/summary/Summary/props'

import { CareRequestType } from './CareRequestType'
import { SkipVisitingPostcodeValidation } from './SkipVisitingPostcodeValidation'

const AccountExecutiveTopAligned = styled(AccountEntry)`
  align-items: flex-start;
`

type Props = {|
  +account: Account,
  +canEdit: boolean,
  +canEditAutomatedComms: boolean,
  +updateStatus: (Object) => void,
  +reactivateAccount: () => void,
  +editSection: (string) => void,
  +cancelArchive: () => void,
  +accountExecutiveHierarchy: Array<ElderUser>,
  +fallbackAccountExecutive: ElderUser,
  +generateAccountExecutiveHierarchy: () => void,
|}

export const AccountInfo = ({
  account,
  canEdit,
  canEditAutomatedComms,
  updateStatus,
  editSection,
  cancelArchive,
  accountExecutiveHierarchy,
  fallbackAccountExecutive,
  reactivateAccount,
  generateAccountExecutiveHierarchy,
}: Props) => (
  <AccountSection>
    <SectionHeader>
      <h3>Account Info</h3>
    </SectionHeader>
    <AccountEntry>
      <span>Status:</span>
      <Stack
        direction={account.status === 'ARCHIVED' ? 'column' : 'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {account.prettyStatus}
          {account.prettyCustomerChurn && !account.prettyCustomerOutcome && (
            <div>
              {account.prettyCustomerChurn}: {account.churnDescription}
            </div>
          )}
          {account.prettyCustomerOutcome && account.prettyCustomerChurn && (
            <div>
              <div>
                {account.prettyCustomerChurn}: {account.churnDescription}
              </div>
              <div>Outcome: {account.prettyCustomerOutcome}</div>
            </div>
          )}
        </Box>
        <StatusButtons
          canEdit={canEdit}
          status={account.status}
          archiveScheduled={account.archiveScheduled}
          updateStatus={updateStatus}
          editSection={editSection}
          cancelArchive={cancelArchive}
          reactivateAccount={reactivateAccount}
        />
      </Stack>
    </AccountEntry>
    <AutomatedCommunicationsEnabled
      isAutomatedCommunicationsEnabled={account.automatedCommunicationsEnabled}
      canEditAutomatedComms={canEditAutomatedComms}
      editSection={editSection}
    />
    <AccountEntry>
      <span>Care Appraisal version</span>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">
          {getLabeledCaVersion(account.appraisalVersion)}
        </Typography>
        <IconButton
          onClick={() => editSection('careAppraisalVersion')}
          title="Edit care appraisal version"
          color="primary"
        >
          <EditOutlined />
        </IconButton>
      </Stack>
    </AccountEntry>
    <AccountEntry>
      <span>Care Appraisal target version</span>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">
          {account.appraisalTargetVersion
            ? getLabeledCaVersion(account.appraisalTargetVersion)
            : 'Not set'}
        </Typography>
        <IconButton
          onClick={() => editSection('careAppraisalTargetVersion')}
          title="Edit care appraisal target version"
          color="primary"
        >
          <EditOutlined />
        </IconButton>
      </Stack>
    </AccountEntry>
    <AccountEntry>
      <span>Salesforce</span>
      <div>
        {account.salesforceId ? (
          <a
            href={`${
              getConfig().salesforceBase
            }/lightning/r/Account/${(account.salesforceId: $FlowFixMe)}/view`}
            target="__blank"
          >
            <code>{account.salesforceId}</code>
          </a>
        ) : (
          'n/a'
        )}
      </div>
    </AccountEntry>
    {(account.status === 'ARCHIVED' || account.archiveScheduled) && (
      <CanAccessPortalWithReadOnlyPermissionsEnabled
        canEditAutomatedComms={account.canAccessPortalWithReadOnlyPermissions}
        editSection={editSection}
        canEdit={canEdit}
      />
    )}
    <CareAgreementSection
      careType={account.prettyCareType}
      canEdit={canEdit}
      editSection={editSection}
    />
    {account.plannedDates && <CareDatesSection dates={account.plannedDates} />}
    {account.relationshipContacts ? (
      <SalesforceOwner
        accountId={account.accountId}
        accountManagerType={account.relationshipContacts.accountManagerType}
      />
    ) : null}
    <AccountExecutiveTopAligned>
      <span>Account Executives:</span>
      <AccountExecutiveHierarchy
        hierarchy={accountExecutiveHierarchy}
        fallback={fallbackAccountExecutive}
        canEdit={canEdit}
        editSection={() => {
          editSection('accountExecutiveHierarchy')
        }}
        generateAccountExecutiveHierarchy={generateAccountExecutiveHierarchy}
      />
    </AccountExecutiveTopAligned>
    {account.relationshipContacts &&
    account.relationshipContacts.detailedSalesExecutiveHierarchy.length ? (
      <AccountExecutiveTopAligned>
        <span>Sales Executives</span>
        <ExecutivesHierarchy
          editSection={() => {
            editSection('salesExecutiveHierarchy')
          }}
          executives={
            account.relationshipContacts.detailedSalesExecutiveHierarchy
          }
        />
      </AccountExecutiveTopAligned>
    ) : null}
    <CareRequestType
      account={account}
      editSection={() => editSection('careRequestType')}
    />
    <SkipVisitingPostcodeValidation account={account} />
  </AccountSection>
)
