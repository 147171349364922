import { useState, useEffect } from 'react'

import type { SetCareRequestTypeParams } from '@elder/et-facade-et'
import { useEtSetCareRequestTypeUsingPUT as setCareRequestType } from '@elder/et-facade-et'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'

import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { pageActions } from 'routes/account/summary/actions'

const options = {
  LIVE_IN: 'Live-in',
  VISITING: 'Visiting',
}

export const SolutionCareTypeForm = ({
  accountId,
  savedCareType,
  cancelEdit,
}: {
  accountId: string
  savedCareType: string
  cancelEdit: () => void
}) => {
  const [selectedType, setVersion] = useState(savedCareType)
  const [displayWarning, setDisplayWarning] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  useEffect(() => {
    if (savedCareType !== selectedType) {
      setDisplayWarning(true)
    }
  }, [selectedType, setDisplayWarning, savedCareType])

  const mutation = setCareRequestType({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Care Request type updated', {
          variant: 'success',
        })
        dispatch(pageActions.get())
        cancelEdit()
      },
      onError: () => {
        enqueueSnackbar('Error updating Care Request type', {
          variant: 'error',
        })
      },
    },
  })

  const handleSave = () => {
    mutation.mutate({
      accountId,
      data: {
        careRequestType: selectedType === 'NOT_SET' ? null : selectedType,
      } as SetCareRequestTypeParams,
    })
  }

  return (
    <Stack spacing={4}>
      <SectionHeader>
        <Typography variant="h6">Care Request Type</Typography>
      </SectionHeader>
      <FormControl>
        <InputLabel id="care-appraisal-version-label">
          Select version
        </InputLabel>
        <Select
          value={selectedType}
          label="Current version"
          onChange={(e) => setVersion(e.target.value)}
          disabled={mutation.isPending || mutation.isError}
        >
          {Object.entries(options)?.map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography
        variant="subtitle2"
        component="p"
        color="error"
        hidden={!displayWarning}
        maxWidth="400px"
      >
        This will change things such as required and optional questions in the
        Care Appraisal, please make sure you are aware of what will change
        before submitting.
      </Typography>

      <Stack direction="row" justifyContent="flex-end">
        <Button
          onClick={cancelEdit}
          variant="outlined"
          disabled={mutation.isPending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={mutation.isPending}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  )
}
